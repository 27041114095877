.council-section {


  text-align: center;
  padding: 100px 0 50px;
  background-color: #37446b;
  color: #FFF;


  h2 {
    margin: 0 0 30px;
  }


  p {
    font-size: 18px;
    line-height: 1.7;
    max-width: 700px;
    margin: 0 auto 50px;
  }


  &__box {
    background-color: #FFF;
    padding: 40px;
    border-radius: 7px;
    color: $body-color;
    height: calc(100% - 30px);
    display: flex;
    align-items: center;
    //justify-content: center;
    margin: 0 0 30px;
    position: relative;
    text-align: left;

    &__check {
      //position: absolute;
      //top: 20px;
      //left: 20px;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $primary;
      color: #FFF;
      flex-shrink: 0;
      margin-right: 30px;
    }


    p {
      font-weight: 700;
      margin-bottom: 0;
      font-size: 18px;
    }

  }

}

.service-section {
  padding: 100px 15px;
  background-color: #f7f7fb;

  p {
    font-size: 18px;
    line-height: 1.7;
    margin: 0 0 50px;

  }



  &__box {
    padding: 40px 50px 50px;
    border: 1px solid #EEE;
    background-color: #FFF;
    border-radius: 7px;
    margin: 0 0 30px;
    @include media-breakpoint-down(md) {
      margin: 0 0 30px;
    }
    @include media-breakpoint-down(sm) {
      padding: 40px 30px 50px;
    }

    &__header {
      font-family: $headings-font-family;
      font-size: 50px;
      line-height: 60px;
      letter-spacing: -1.39px;
      font-weight: 900;
      border-bottom: 1px solid #D2D2D2;
      padding: 0 0 40px;
      margin: 0 0 50px;
      display: block;
      color: #60cfc1;
      @include media-breakpoint-down(sm) {
        font-size: 40px;
        line-height: 60px;
        letter-spacing: -1.11px;
      }
    }

    h3 {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.2px;
      font-weight: 700;
      margin: 0 0 60px;
    }

    p {
      //color: rgba($body-color, 0.5);
      margin: 0;
    }


  }
}

.provider-logos {
  padding: 0 0 40px;

  p {
    margin: -25px 30px 30px;
    font-weight: 900;
    color: #FFF;
    background-color: $primary;
    position: relative;
    padding: 15px;
    border-radius: 7px;
    text-align: center;
    font-size: 18px;
    &:before{
      position: absolute;
      content: "";
      display: block;
      top: -15px;
      left: 50%;
      height: 30px;
      width: 30px;
      background-color: $primary;
      transform: rotate(45deg) translate(-50%, 0);
      transform-origin: left;
    }
  }

  .container {
    max-width: 900px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
.triad-section {
  padding: 70px 0;
  border-bottom: 1px solid $border-color;
  text-align: center;
  &__box {

    &__number {
      display: flex;
      height: 80px;
      width:80px;
      margin: 30px auto 30px;
      border-radius: 50%;
      background-color: $primary;
      color: #FFF;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 900;
    }

    h4 {

    }
    p {
      font-size: 16px;
      line-height: 1.7;
    }

  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {

    }
  }

}
