
$primary:       #60cfc1;
$green: #60cfc1;


$font-family-base:            'Sintony', sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              0.875rem;
$font-weight-base:            400;

$headings-font-family:        'Source Sans Pro', sans-serif;
$headings-font-weight:        900;
$headings-margin-bottom:      30px;


//$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                40px;
$h3-font-size:                32px;
//$h4-font-size:                $font-size-base * 1.5 !default;
//$h5-font-size:                $font-size-base * 1.25 !default;
//$h6-font-size:                $font-size-base !default;


// Body
//
// Settings for the `<body>` element.

$body-bg:                   #FFF;
$body-color:                #25345b;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               15px;
$btn-padding-x:               15px;
$btn-font-size:               15px;
$btn-line-height:             1.2;

//
//$btn-border-width:            $input-btn-border-width !default;
//
$btn-font-weight:             600;
//$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
//$btn-focus-width:             $input-btn-focus-width !default;
//$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
//$btn-disabled-opacity:        .65 !default;
//$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;
//
//$btn-link-disabled-color:     $gray-600 !default;
//
//$btn-block-spacing-y:         .5rem;
//
$btn-border-radius:           8px;
$btn-border-radius-lg:        $btn-border-radius;
$btn-border-radius-sm:        $btn-border-radius;



// Forms

$input-padding-y:                       0.7rem;
$input-padding-x:                       0.75rem;
//$input-font-family:                     $input-btn-font-family !default;
//$input-font-size:                       $input-btn-font-size !default;
//$input-font-weight:                     $font-weight-base !default;
$input-line-height:                     1.5;

$input-height-inner:                    ($font-size-base * $input-line-height) + ($input-padding-y * 2) !default;
$input-height:                          calc(#{$input-height-inner} + 2px) !default;


// Cards

$card-spacer-y:                     0;
$card-spacer-x:                     0;
//$card-border-width:                 $border-width !default;
//$card-border-radius:                $border-radius !default;
//$card-border-color:                 rgba($black, .125) !default;
//$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:                       #FFF;
//$card-bg:                           $white !default;
//
//$card-img-overlay-padding:          1.25rem !default;
//
//$card-group-margin:                 ($grid-gutter-width / 2) !default;
//$card-deck-margin:                  $card-group-margin !default;
//
//$card-columns-count:                3 !default;
//$card-columns-gap:                  1.25rem !default;
//$card-columns-margin:               $card-spacer-y !default;
