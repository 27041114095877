.invalid-feedback-force {
  display: block !important;
}


label {
  font-weight: bold;
}

.form-stage {

}

.form-stage-enter-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;

}
.form-stage-enter-done {
  animation-duration: 0.7s;
  animation-delay: 0;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.7;
  -webkit-animation-delay: 0;
  -webkit-animation-fill-mode: both;
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;

}
.form-stage-exit-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 200ms;

}
.form-stage-exit-done {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
}


.form-group {
  &--hide {
    display: none;
  }

}


select {
  -webkit-appearance: none;
  background-image: url("../../img/arrow-down-black.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.input-group-prepend {
  width: 44px;
  text-align: center;
  .input-group-text {
    width: 100%;
    text-align: center;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
}


.main-form-container {
  background-image: url("../../../src/img/intro3.jpg");
  background-size: cover;
  background-color: #f7f7fb;
  padding: 70px 0px 100px;


  &__logos {
    margin: -20px -30px 0;
    padding: 0 0 50px;
    text-align: center;
    img {
      max-width: 100%;
    }
    p {
      font-weight: bold;
    }
  }


  .form-title {
    margin: -20px 0 20px;
  }

  &__points {

    text-align: center;


    &__header {
      background-color: #fff;
      padding: 30px;
      position: relative;
      margin: 0 0 40px;
      border-radius: 7px;
      opacity: 0.9;

      &:after {
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        left: 50%;
        bottom: -20px;
        content: "";
        background-color: #FFF;
        transform: rotate(45deg) translate(-50%, 0);
        transform-origin: left;
      }

      h1 {
        margin: 0 0 10px;
        //color: #FFF;
      }

      p {
        margin: 0;
        //color: #FFF;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.7;
      }

    }

    &__body {
      color: #FFF;

      p {
        font-size: 18px;
        line-height: 1.7;
      }

      h2 {
        font-size: 24px;
        margin: 0;
      }

      ul {
        padding: 15px 0 20px;
        list-style: none;
        margin: 0 -20px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        li {
          margin: 15px 20px;
          .fas {
            color: $primary;
            margin-right: 8px;
          }
        }
      }

      &--2 {
        text-align: center;
        padding: 40px 20px 0;

        ul {
          padding-bottom: 0;
        }
      }

    }



  }


}


.main-form {
  position: relative;
  overflow: hidden;
  padding: 50px 50px 70px;
  @include media-breakpoint-down(sm){
    padding-left: 30px;
    padding-right: 30px;
  }
  max-width: 500px;
  margin: 0 auto;
  background-color: #FFF;
  border: #d6d6d6 1px solid;
  border-radius: 5px;
  transition: all 0.5s ease;
  &__body {
    min-height: 240px;
    position: relative;
  }

  &__buttons {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    .w-100 {
      flex-shrink: 0;
      flex-basis: 100%;
    }

    &__back {
      background-color: transparent;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      position: absolute;
      bottom: -45px;
      left: 0;
      color: #ccc;
      &:hover {
        text-decoration: none;
      }

    }

    &__legal {
      padding: 40px 0 0;
      text-align: center;
      p {
        text-align: left;
        color: $text-muted;
        margin: 0;
        font-size: 12px;
        line-height: 1.9;
        span {
          cursor: pointer;
        }

      }
      img {
        margin: 30px 20px;
      }

      &__strong {
        font-weight: 900;
        text-align: center;
        i {
          margin-right: 8px;
        }
      }

    }

  }

  &__processing {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#FFF,1);
    align-items: center;
    justify-content: center;
    padding: 40px;
    z-index: 999;
    &--active {
      display: flex;
    }
    &__icon {
      width: 100px;
      max-width: 100%;
      height: auto;
      -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
      animation: heartbeat 1.5s ease-in-out infinite both;
    }

    p {
      text-align: center;
      font-weight: 700;
    }
  }
}



