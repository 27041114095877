.thanks1 {

  padding: 100px 0 100px;
  background-color: #fdfdfd;
  position: relative;

  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  &__tick {
    display: flex;
    height: 80px;
    margin: 0 0 20px;
    justify-content: center;
    align-items: center;
    &__inner {
      display: flex;
      border-radius: 50%;
      background-color: #000;
      color: #FFF;
      justify-content: center;
      align-items: center;
      font-size: 35px;
      opacity: 0;
      transition: all .3s ease;

      i {
        opacity: 0.2;
        color: transparent;
        transition: opacity .3s .1s ease;
        -webkit-text-stroke: 3px rgba(0,0,0,.5);
    }

      &--animate {
        animation: check .5s cubic-bezier(0.895, 0.030, 0.685, 0.220) forwards;

        i {
          opacity: 1;
          transform: scale(0);
          color: white;
          -webkit-text-stroke: 0;
          animation: icon .3s cubic-bezier(1.000, 0.008, 0.565, 1.650) .1s 1 forwards;
      }

    }

  }


}

  &__message {
    text-align: left;
    max-width: 900px;
    padding: 0 20px;
    margin: 0 auto;
    h1 {
      margin: 0 0 30px;
  }
    p {
      font-size: 16px;
      line-height: 32px;
  }
}

}


@keyframes icon {
from {
opacity: 0;
transform: scale(0.3);
}
to {
opacity: 1;
transform: scale(1)
}
}

@keyframes check {
0% {
width: 1.5em;
height: 1.5em;
border-width: 5px;
}
10% {
width: 1.5em;
height: 1.5em;
opacity: 0.1;
background: rgba(0,0,0,0.2);
border-width: 15px;
}
12% {
width: 1.5em;
height: 1.5em;
opacity: 0.4;
background: rgba(0,0,0,0.1);
border-width: 0;
}
50% {
width: 2.25em;
height: 2.25em;
background: $primary;
border: 0;
opacity: 0.6;
}
100% {
width: 2.25em;
height: 2.25em;
background:$primary;
border: 0;
opacity: 1;
}
}


.broker {
  border-bottom: 1px solid $border-color;
  position: relative;
  padding: 70px 20px;
  &__title {
    display: inline-block;
    padding: 5px 30px;
    position: absolute;
    top: -20px;
    border-radius: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    white-space: pre;
    font-size: 15px;
    font-weight: bold;
    line-height: 30px;
    background-color: $primary;
    color: #FFF;
}

  &__card {
    max-width: 500px;
    margin: 0 auto;
    padding: 50px;
    border:1px solid $border-color;
    border-radius: 7px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        padding: 5px 0;
        i {
          color: $text-muted;
          margin-right: 8px;
      }
    }
  }
}

}
