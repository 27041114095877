.sa_reviews {
  padding: 100px 0;
  background-color: #37446b;
  color: #fff;

  position: relative;

  &::before {
    position: absolute;
    top: 10px;
    left: 100px;
    font-size: 100px;
    @include media-breakpoint-down(sm) {
      left: 20px;
      font-size: 50px;
    }
    display: block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: #60cfc1;

    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f10d";
  }

  &::after {
    position: absolute;
    bottom: 10px;
    right: 100px;
    display: block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: #60cfc1;
    font-size: 100px;
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f10e";
    @include media-breakpoint-down(sm) {
      right: 20px;
      font-size: 50px;
    }
  }

  .sa_info {
    float: none !important;
    width: auto;
    padding: 0 !important;
    font-size: 18px !important;
    .sa_displayname {
      padding: 0;
    }
  }

  .sa_comments {
    &::before,
    &::after {
      display: none !important;
    }
    text-align: center !important;
    margin: 20px 0 !important;
    font-size: 26px;
    font-style: italic;
    font-family: Georgia, serif;
  }



  .sa_review {
    text-align: center;
  }

  .sa_star {
    background: none !important;
    margin: 0 5px 30px;
    &::before {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      color: #FFF;
      font-size: 20px;
      font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f005";
    }

    &.sa_activestar {
      &::before {
        color: #ee6540;
      }
    }

  }


  .sa_date {
    font-size: 14px !important;
  }
}
