

.card-header {
  .btn {
    font-size: 18px;
    font-weight: 700;
    padding: 30px 50px;
    width: 100%;
    text-align: left;
    text-decoration: none;
    border-radius: 0;
    &:active, &:focus {
      background-color: #37446b;
      color: #FFF;
    }

  }

}


.card-header + .show {

}

.card-body {
  padding: 50px;
  border-bottom: 1px solid $border-color;

  p {
    &:last-child{
      margin-bottom: 0;
    }
  }
}
