.main-footer {
    padding: 50px 0 50px;
    font-size: 15px;
    font-size: 300;

  p {
    margin: 0 0 40px;
    color: rgba($body-color, 0.7);
  }

  .menu-links {

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
      margin: 0;
      @include media-breakpoint-down(sm) {
        margin: 0 0 30px;
        flex-basis: 100%;
        text-align: center;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 0 auto;
      @include media-breakpoint-down(sm) {
        margin: 0 auto ;
      }
      display: flex;
      li {
        margin-left: 30px;
        @include media-breakpoint-down(sm) {
          margin: 0 10px;
        }
        a {
          color: $body-color;
        }
      }
    }

  }


}


body.popup-open {
  overflow: hidden;
}

.pop-up-outer{
  display: none;
  &--open {
    display: block;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.85);
}
.pop-up {
  position: fixed;
  top: 50px;
  bottom: 50px;
  left: 0;
  right: 0;
  background-color: #FFF;
  padding: 50px;
  border-radius: 7px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  overflow-y: auto;

  &__close {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 40px;
    width: 40px;
    background-color: $red;
    border-radius: 20px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover{
      background-color: #000;
    }

  }

  table {
    width: 100%;
    td,th {
      vertical-align: top;
    }
  }
}
