.progress {
  position: relative;

  &__num {
    position: absolute;
    font-size: 13px;
    color: #000;
    font-weight: bold;
    left: 50%;
    top: 50%;
    width: 150px;
    line-height: 20px;
    height: 20px;
    margin: -10px 0 0 -75px;
    text-align: center;
    display: block;
  }
}
