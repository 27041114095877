.main-header {
  height: 70px;
  display: flex;
  align-items: center;
  //text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  &__logo {
    width: 150px;
    max-width: 100%;
    height: auto;
  }

}
